import moment from 'moment';
import { tickets } from '../types';

export const removeFromTicketsStore = (type) => ({
    type: tickets.REMOVE_FROM_TICKETS_STORE_REQUEST,
    payload: { type },
});

export const getBOQList = (customerId, genre) => ({ type: tickets.BOQ_LIST_REQUEST, payload: { customerId, genre } });

export const updateTicketProperties = ({ data, formName, closeModal, updateTicketList }) => ({
    type: tickets.UPDATE_TICKET_PROPERTIES_REQUEST,
    payload: { data, formName, closeModal, updateTicketList },
});

export const updateSignOffNotes = ({ ticketId, data = {}, download = false, formName }) => ({
    type: tickets.UPDATE_SIGN_OFF_NOTES_REQUEST,
    payload: { ticketId, data, download, formName },
});

export const setTicketSideDrawer = (showTicketSideDrawer, sideBarName, ticketSideDrawerPayload) => ({
    type: tickets.SET_TICKETS_SIDEDRAWER,
    payload: { showTicketSideDrawer, sideBarName, ticketSideDrawerPayload },
});

export const createSupportTickets = ({ data, existing, addNew, formName }) => ({
    type: tickets.CREATE_SUPPORT_TICKETS_REQUEST,
    payload: { data, existing, addNew, formName },
});

export const createIndependentTickets = ({ data, existing, addNew, formName, onSuccess, refresh, onFailure }) => ({
    type: tickets.CREATE_INDEPENDENT_TICKETS_REQUEST,
    payload: { data, existing, addNew, formName, onSuccess, refresh, onFailure },
});

export const notifyIsp = ({ data, whatsappUrl, formName }) => ({
    type: tickets.NOTIFY_TICKETS_TO_ISP_REQUEST,
    payload: { data, whatsappUrl, formName },
});

export const respondFeasibility = ({ data, refresh, ticketId, ispId, closeModal = true, formName, isGetAssignedNewTicketList, isGetSpList }) => ({
    type: tickets.RESPOND_FEASIBILITY_TICKET_REQUEST,
    payload: { data, closeModal, refresh, ticketId, ispId, formName, isGetAssignedNewTicketList, isGetSpList },
});

export const viewTicketById = ({ ticketId, externalTicketId }) => ({
    type: tickets.VIEW_TICKET_REQUEST,
    payload: { ticketId, externalTicketId },
});

export const getExecDateTimeline = (ticket_id) => ({
    type: tickets.GET_EXECUTION_DATE_TIMELINE_REQUEST,
    payload: { ticket_id },
});

export const updateCourierStatus = ({ trackingId, type, ticketId }) => ({
    type: tickets.UPDATE_TICKET_COURIER_STATUS_REQUEST,
    payload: { trackingId, type, ticketId },
});

export const clearPaymentSidebarStoreData = () => ({
    type: tickets.CLEAR_PAYMENTS_SIDEBAR_DATA_FROM_STORE,
});

export const getChildTickets = (ticketId) => ({
    type: tickets.GET_CHILD_TICKETS_REQUEST,
    payload: { ticketId },
});

export const getSOWDocument = (ticketId, download) => ({
    type: tickets.SOW_DOCUMENT_REQUEST,
    payload: { ticketId, download },
});

export const getSignOffSampleReport = (ticketId, download) => ({
    type: tickets.SIGN_OFF_SAMPLE_REPORT_REQUEST,
    payload: { ticketId, download },
});

export const getTicketDetails = ({ ticketId, payment_transaction = false }) => ({
    type: tickets.GET_TICKET_DETAILS_REQUEST,
    payload: { ticketId, payment_transaction },
});

export const getTicketDetailForDetailPage = ({ ticketId, shared_by }) => ({
    type: tickets.GET_TICKET_DETAILS_FOR_DETAIL_PAGE_REQUEST,
    payload: { ticketId, shared_by }
})
export const getTicketReportLink = ({ ticketId, download, popError }) => ({
    type: tickets.TICKET_REPORT_LINK_REQUEST,
    payload: { ticketId, download, popError },
});

export const emailReport = ({ data, downloadReport }) => ({
    type: tickets.EMAIL_REPORT_REQUEST,
    payload: { data, downloadReport },
});

export const zipTicketReports = ({ ticketsData, reportType, emails = [], formName }) => ({
    type: tickets.ZIP_TICKET_REPORTS_REQUEST,
    payload: { ticketsData, reportType, emails, formName },
});

export const getTicketPayments = ({ ticketId, incentive, user_type }) => ({
    type: tickets.GET_TICKET_PAYMENTS_REQUEST,
    payload: { ticketId, incentive, user_type },
});

export const cleanupTicketDetails = () => ({
    type: tickets.CLEANUP_TICKET_DETAILS,
    payload: {},
});

export const cleanupTicketPaymentDetails = () => ({
    type: tickets.CLEANUP_TICKET_PAYMENT_DETAILS,
    payload: {},
});

export const requestAdditionalAmount = ({ data, proofs, closeModal, refreshPayments, refreshViewTicket, idx, formName, onSuccess }) => ({
    type: tickets.REQUEST_ADDITIONAL_AMOUNT_REQUEST,
    payload: { data, proofs, closeModal, refreshPayments, refreshViewTicket, idx, formName, onSuccess },
});

export const processAdditionalAmount = ({ data, refreshPayments, closeModal, activeTab, projectId, customerId, limit, page, idx, formName }) => ({
    type: tickets.PROCESS_ADDITIONAL_AMOUNT_REQUEST,
    payload: { data, refreshPayments, closeModal, activeTab, projectId, customerId, limit, page, idx, formName },
});

export const approveTicketSignOff = ({ data, isRefresh, navigateBack, navigate, closeModal, formName }) => ({
    type: tickets.APPROVE_TICKET_SIGNOFF_REQUEST,
    payload: { data, isRefresh, navigateBack, navigate, closeModal, formName },
});

export const getTicketsList = ({ count, type, next, offset, limit, page, customerId, tab, view, lastScannedIndex, sortBy }) => ({
    type: tickets.GET_TICKET_LIST_REQUEST,
    payload: { count, type, next, offset, limit, page, customerId, tab, view, lastScannedIndex, sortBy },
});

export const putToTicketStore = (payload) => ({
    type: tickets.PUT_TO_TICKETS_STORE,
    payload,
});

export const getTicketsTodayStatus = ({ limit, page, count = false, projectId, searchQuery, stateId, customerId }) => ({
    type: tickets.TICKETS_TODAY_STATUS_LIST_REQUEST,
    payload: { limit, page, count, projectId, searchQuery, stateId, customerId },
});

export const addfeIntentionStatus = ({ ticketId, intention, formName, refresh }) => ({
    type: tickets.ADD_FE_INTENTION_STATUS_REQUEST,
    payload: { ticketId, intention, formName, refresh },
});

export const initiateTicket = ({ ticketId, serviceTypeId, formName, sign_off_response, intention, navigate, ticketExecution = false, refresh, updateReachedSite, closeModal }) => ({
    type: tickets.INITIATE_TICKET_REQUEST,
    payload: { ticketId, sign_off_response, serviceTypeId, formName, intention, navigate, ticketExecution, refresh, updateReachedSite, closeModal },
});

export const updateNocExecutionData = ({ formValues, intention, ticket, formName, refresh }) => ({
    type: tickets.UPDATE_NOC_EXECUTION_DATA_REQUEST,
    payload: { formValues, ticket, intention, formName, refresh },
});

export const siteCompleted = ({ ticketId, formName, refresh, callViewTicket, sign_off_quality_comment, sign_off_quality_rating, work_quality_comment, work_quality_rating }) => ({
    type: tickets.SITE_COMPLETED_REQUEST,
    payload: { ticketId, formName, refresh, callViewTicket, sign_off_quality_comment, sign_off_quality_rating, work_quality_comment, work_quality_rating },
});

export const updateTodaysStatusTicketProperties = ({ data, formName, intention, refresh }) => ({
    type: tickets.UPDATE_TODAY_STATUS_TICKET_PROPERTIES_REQUEST,
    payload: { data, formName, intention, refresh },
});

export const setTodayStatusFilterAndPaginationData = ({ payload, isFilterActive, page, limit, query }) => ({
    type: tickets.SET_TODAY_STATUS_FILTER_AND_PAGINATION_DATA,
    payload: { payload, isFilterActive, page, limit, query },
});

export const getTodayStatusFilterData = ({ customerId, limit, page, query, payload, count }) => ({
    type: tickets.GET_TODAY_STATUS_FILTER_DATA_REQUEST,
    payload: { customerId, limit, page, query, payload, count },
});
export const getSPAcceptedTickets = ({ type, order_by, limit, page }) => ({
    type: tickets.GET_SP_ACCEPTED_TICKETS_REQUEST,
    payload: { type, order_by, limit, page },
});

export const getFEAcceptedTickets = ({ type, order_by, limit, page }) => ({
    type: tickets.GET_FE_ACCEPTED_TICKETS_REQUEST,
    payload: { type, order_by, limit, page },
});

export const getStatusWiseTicketCount = () => ({
    type: tickets.GET_SP_TICKETS_COUNT_REQUEST,
});

export const getFETicketsCount = () => ({
    type: tickets.GET_FE_TICKETS_COUNT_REQUEST,
});

export const getTicketFilterList = ({ toFilter, projectIds }) => ({
    type: tickets.GET_TICKET_FILTER_LIST_REQUEST,
    payload: { toFilter, projectIds },
});

export const getSPTickets = ({ order_by, limit, page }) => ({
    type: tickets.GET_SP_NEW_TICKETS_LIST_REQUEST,
    payload: { order_by, limit, page },
});

export const getAssetConfigTemplate = (ticketId) => ({
    type: tickets.GET_ASSETS_CONFIG_TEMPLATE_REQUEST,
    payload: { ticketId },
});

export const getTicketFilteredList = ({ limit, page, payload, query, tab, isSupportPath, navigate, closeModal }) => ({
    type: tickets.GET_TICKET_FILTERED_LIST_REQUEST,
    payload: { limit, page, payload, query, tab, isSupportPath, navigate, closeModal },
});

export const getTicketAssetsOrRemarks = ({ ticketIds }) => ({
    type: tickets.GET_TICKET_ASSETS_REMARKS_REQUEST,
    payload: { ticketIds },
});

export const assignSPManually = ({ data, formName, callViewTicket }) => ({
    type: tickets.ASSIGN_SP_MANUALLY_REQUEST,
    payload: { data, formName, callViewTicket },
});

export const broadcastTicketManually = ({ data, formName, callViewTicket }) => ({
    type: tickets.ASSIGN_SP_MANUALLY_REQUEST,
    payload: { data, formName, callViewTicket },
});

export const setTicketFilterAndPaginationData = ({ payload, page, limit, query, tab, mainTab }) => ({
    type: tickets.SET_TICKET_FILTER_DATA,
    payload: { payload, page, limit, query, tab, mainTab },
});

export const closeTicket = ({ data, ticket, formName, ticketExecution = false, navigate, isSupportTicket }) => ({
    type: tickets.CLOSE_TICKET_REQUEST,
    payload: { data, ticket, formName, ticketExecution, navigate, isSupportTicket },
});

export const editExecutionDate = ({ ticketId, edited_execution_date, change_msg, formName, refresh, update_planned_date }) => {
    const formData = new FormData();
    formData.append('ticket_id', ticketId);
    formData.append('edited_execution_date', moment(edited_execution_date).format('DD-MM-YYYY HH:mm:ss'));
    formData.append('change_msg', change_msg);
    formData.append('update_planned_date', update_planned_date);
    return {
        type: tickets.EDIT_EXECUTION_DATE_REQUEST,
        payload: { form: formData, formName, refresh, update_planned_date },
    };
};

export const reAssignSPManually = ({ execution_date, ticket_instruction, ticketList, sp, formName, callViewTicket, refresh, update_planned_date, partner_base_payment }) => ({
    type: tickets.REASSIGN_SP_MANUALLY_REQUEST,
    payload: { execution_date, ticket_instruction, ticketList, sp, formName, callViewTicket, refresh, update_planned_date, partner_base_payment },
});

export const changeTicketWarehouse = ({ bulk, data, formName, ticketId, refresh, refreshViewTicket }) => ({
    type: tickets.CHANGE_TICKET_WAREHOUSE_REQUEST,
    payload: { bulk, data, formName, ticketId, refresh, refreshViewTicket },
});

export const updateTicketRMADetails = ({ data, formName, ticketId, refresh }) => ({
    type: tickets.UPDATE_TICKET_RMA_DETAILS_REQUEST,
    payload: { data, formName, ticketId, refresh },
});

export const getTicketDetailsById = (({ ticketId }) => ({
    type: tickets.GET_TICKET_DETAILS_BY_ID_REQUEST,
    payload: { ticketId }
}))
export const acceptAsset = ({ site_completed_on, job_sign_off_notes, formName, ticketId }) => ({
    type: tickets.ACCEPT_REJECT_TICKET_ASSET_REQUEST,
    payload: { site_completed_on, job_sign_off_notes, formName, ticketId, actionType: 'APPROVE' },
});

export const rejectAsset = ({ job_sign_off_notes, formName, ticketId }) => ({
    type: tickets.ACCEPT_REJECT_TICKET_ASSET_REQUEST,
    payload: { job_sign_off_notes, formName, ticketId, actionType: 'REJECT' },
});

export const updateDocketDetails = ({ data, formName, callCloseTicket }) => ({
    type: tickets.UPDATE_TICKET_DOCKET_DETAILS_REQUEST,
    payload: { data, formName, callCloseTicket },
});

export const closeFeasibiblity = ({ data, formName, ticketId, refresh, navigate, openRaisePoModal, job_ticket_number }) => ({
    type: tickets.CLOSE_FEASIBILITY_REQUEST,
    payload: { data, formName, ticketId, refresh, navigate, openRaisePoModal, job_ticket_number },
});

export const clearAssignSpNewTicketFromStore = () => ({
    type: tickets.CLEAR_SP_ASSIGNED_NEW_TICKETS_LIST,
});

export const assignTechSupportUser = ({ userIds, formName, refresh, ticketId, ticketIds }) => ({
    type: tickets.ASSIGN_TECH_SUPPORT_USERS_REQUEST,
    payload: { userIds, formName, refresh, ticketId, ticketIds },
});

export const updateCustomerApprovalRecieved = ({ data, formName, refresh }) => ({
    type: tickets.UPDATE_CUSTOMER_APPROVAL_RECIEVED_REQUEST,
    payload: { data, formName, refresh },
});

export const cloneTickets = ({ ticketsList, projectId, customerId, formName, refresh }) => {
    let formData = new FormData();
    ticketsList.map((ticketId, index) => {
        return formData.set(`tickets[${index}]`, ticketId);
    });
    return {
        type: tickets.CLONE_TICKETS_REQUEST,
        payload: { formData, projectId, customerId, formName, refresh },
    };
};

export const clearViewTicketFromStore = () => {
    return {
        type: tickets.CLEAR_VIEW_TICKET_FROM_STORE,
    };
};

export const bulkEditExecutionDate = ({ ticketIds, edited_execution_date, change_msg, formName, refresh, update_planned_date }) => {
    const formData = new FormData();
    ticketIds.forEach((ticketId, index) => {
        formData.set(`tickets[${index}]`, ticketId);
    });
    formData.set('edited_execution_date', moment(edited_execution_date).format('DD-MM-YYYY HH:mm:ss'));
    formData.set('change_msg', change_msg);
    formData.set('update_planned_date', update_planned_date);
    return {
        type: tickets.BULK_EDIT_EXECUTION_DATE_REQUEST,
        payload: { form: formData, formName, refresh },
    };
};
export const approveOrRejectPo = ({ data, formName, navigate, ticketId, refresh }) => ({
    type: tickets.APPROVE_OR_REJECT_PO_REQUEST,
    payload: { data, formName, navigate, ticketId, refresh },
});

export const removePOApprover = ({ teamId, userId, ticketId, formName, updateReducer }) => ({
    type: tickets.REMOVE_PO_APPROVER_REQUEST,
    payload: { teamId, userId, ticketId, formName, updateReducer },
});

export const resumeTicket = ({ data, formName }) => ({
    type: tickets.RESUME_TICKET_REQUEST,
    payload: { data, formName },
});
export const bulkResumeTicket = ({ data, formName }) => ({
    type: tickets.BULK_RESUME_TICKET_REQUEST,
    payload: { data, formName },
});

export const deleteTicketsRequest = ({ data, formName }) => ({
    type: tickets.TICKET_DELETE_REQUEST,
    payload: { data, formName },
});

export const requestTrackFE = ({ ticketId, formName }) => ({
    type: tickets.REQUEST_TRACK_FE,
    payload: { ticketId, formName },
});

export const getPoDetails = ({ ticketId }) => ({
    type: tickets.GET_PO_DETAILS,
    payload: { ticketId },
});

export const hardCloseTicket = ({ data, formName }) => ({
    type: tickets.HARD_CLOSE_TICKET_REQUEST,
    payload: { data, formName },
});

export const getTicketRemarks = ({ ticketId }) => ({
    type: tickets.GET_TICKET_REMARKS,
    payload: { ticketIds: ticketId },
});

export const addMigrationAssets = ({ ticketId, assets, formName, onSuccess }) => ({
    type: tickets.ADD_MIGRATION_ASSETS_REQUEST,
    payload: { ticketId, assets, formName, onSuccess },
});

export const engineerRatingAndComment = ({
    formName,
    closeModal,
    fk_ticket_id,
    userId,
    sign_off_quality_comment,
    sign_off_quality_rating,
    work_quality_comment,
    work_quality_rating,
}) => ({
    type: tickets.ENGINEER_RATING_AND_COMMENT_REQUEST,
    payload: { formName, closeModal, sign_off_quality_comment, sign_off_quality_rating, work_quality_comment, work_quality_rating, fk_ticket_id, userId },
});
export const editTicket = ({
    formName,
    onSuccess,
    ticketId,
    projectId,
    endUserId,
    clusterId,
    pspId,
    supervisorId,
    externalTicketId,
    sla,
    issueCategory,
    executionDate,
    remarks,
    signOffTemplate,
    revisitRequired,
    escalationMessage,
    closedByNoc,
    techEngineers,
    description,
    pincode,
    cityId,
    assets,
    siteId,
    externalSiteId,
    siteAddress,
    branch,
    region,
    zone,
    contactName,
    contactMobile,
    contactEmail,
    forwardCourierVendor,
    reverseCourierVendor,
    forwardDocketNumber,
    reverseDocketNumber,
    forwardCost,
    reverseCost,
    forwardEta,
    reverseEta,
    hardCopyRequired,
    hardCopyReceived,
    assetPickupRequired,
    assetPickupPincode,
    assetPickupAddress,
    assetShippingPincode,
    assetShippingAddress,
    isRequestSupport,
    updateTicketDetails,
    toastSuccessMessage,
    supportAsset,
    navigate,
    redirectTo,
    closeModal,
    update_planned_date = 0
}) => {
    const form = new FormData();
    form.set('ticket_id', ticketId);
    if (isRequestSupport) {
        form.set('isRequestSupport', isRequestSupport);
        form.set('complete_site_address', siteAddress);
        if (executionDate) form.set('execution_date', moment(executionDate).format('DD-MM-YYYY HH:mm:ss'));
        if (isRequestSupport && supportAsset) {
            form.set(`boq_data[0][asset_type_id]`, supportAsset.asset_type_id);
            form.set(`boq_data[0][serial_number]`, supportAsset.serial_number);
            form.set(`boq_data[0][partcode]`, supportAsset.partcode);
            form.set(`boq_data[0][quantity]`, 1);
        }
    }
    if (projectId >= 0) form.set('fk_project_id', projectId);
    if (endUserId >= 0) form.set('end_user_id', endUserId);
    if (clusterId >= 0) form.set('cluster_id', clusterId);
    if (pspId >= 0) form.set('psp_id', pspId);
    if (supervisorId >= 0) form.set('supervisor_id', supervisorId);
    form.set('external_ticket_id', externalTicketId);
    if (sla >= 0) form.set('sla', sla);
    if (issueCategory >= 0) form.set('issue_category', issueCategory);
    if (remarks) {
        form.set('execution_date', moment(executionDate).format('DD-MM-YYYY HH:mm:ss'));
        form.set('change_msg', remarks);
    }
    if (signOffTemplate >= 0) form.set('fk_sign_off_template_id', signOffTemplate);
    if (revisitRequired !== 'dont_send') {
        form.set('is_revisit_required', revisitRequired);
        if (escalationMessage) form.set('escalation_message', escalationMessage);
    }
    if (closedByNoc > 0) form.set('closed_by_noc', closedByNoc);
    if (techEngineers) form.set('tech_engineers', techEngineers);
    form.set('description', description);
    if (pincode) form.set('job_fk_pincode_id', pincode);
    if (cityId) form.set('job_fk_city_id', cityId);
    if (siteId === 'add_new_site') {
        form.set('site_id', 0);
        if (externalSiteId) form.set('external_site_id', externalSiteId);
        if (siteAddress) form.set('complete_site_address', siteAddress);
        if (branch) form.set('site_branch_name', branch);
        if (region) form.set('region', region);
        if (zone) form.set('zone', zone);
    } else {
        form.set('site_id', siteId);
    }
    if (typeof contactName === 'string') form.set('job_site_contact_name', contactName);
    if (typeof contactMobile === 'string') form.set('job_site_contact_mobile', contactMobile);
    if (typeof contactEmail === 'string') form.set('job_site_contact_mail', contactEmail);

    assets?.forEach?.((asset, i) => {
        form.set(`boq_data[${i}][quantity]`, asset.quantity);
        form.set(`boq_data[${i}][asset_type_id]`, asset.asset_type_id);
        if (typeof asset.serial_number === 'string') form.set(`boq_data[${i}][serial_number]`, asset.serial_number);
        if (typeof asset.make === 'string') form.set(`boq_data[${i}][make]`, asset.make);
        if (typeof asset.model === 'string') form.set(`boq_data[${i}][model]`, asset.model);
        if (typeof asset.partcode === 'string') form.set(`boq_data[${i}][partcode]`, asset.partcode);
        if (typeof asset.external_order_id === 'string') form.set(`boq_data[${i}][external_order_id]`, asset.external_order_id);
        form.set(`boq_data[${i}][req_to_delete]`, asset.req_to_delete);
        // only send id when there is no warehouse_id (not even 0)
        if (asset.assetId > 0 && isNaN(parseInt(asset.warehouse_id, 10))) {
            form.set(`boq_data[${i}][id]`, asset.assetId);
        }
    });

    if (forwardCourierVendor > 0) form.set('forwardCourierVendor', forwardCourierVendor);
    if (reverseCourierVendor > 0) form.set('reverseCourierVendor', reverseCourierVendor);
    if (forwardCourierVendor === 0) form.set('forwardCourierVendor', 'hand_held');
    if (reverseCourierVendor === 0) form.set('reverseCourierVendor', 'hand_held');
    if (forwardDocketNumber) form.set('forwardDocketNumber', forwardDocketNumber);
    if (reverseDocketNumber) form.set('reverseDocketNumber', reverseDocketNumber);
    if (forwardCost) form.set('forwardCost', forwardCost);
    if (reverseCost) form.set('reverseCost', reverseCost);
    if (forwardCourierVendor === 0 && forwardEta && moment(forwardEta).isValid()) form.set('forwardEta', moment(forwardEta).format('YYYY-MM-DD'));
    if (reverseCourierVendor === 0 && reverseEta && moment(reverseEta).isValid()) form.set('reverseEta', moment(reverseEta).format('YYYY-MM-DD'));

    if (hardCopyRequired !== 'dont_send') form.set('hard_copy_required', hardCopyRequired);
    if (hardCopyReceived !== 'dont_send') form.set('hard_copy_received', hardCopyReceived);
    if (assetPickupRequired !== 'dont_send') form.set('self_pickup_required', assetPickupRequired);
    if (assetPickupPincode) form.set('job_pickup_fk_pincode_id', assetPickupPincode);
    if (assetPickupAddress) form.set('job_pickup_address_1', assetPickupAddress);
    if (assetShippingPincode) form.set('job_shipping_fk_pincode_id', assetShippingPincode);
    if (assetShippingAddress) form.set('job_shipping_address_1', assetShippingAddress);
    form.set('update_planned_date', update_planned_date);
    return {
        type: tickets.EDIT_TICKET_REQUEST,
        payload: { form, formName, onSuccess, updateTicketDetails, toastSuccessMessage, supportAsset, ticketId, navigate, redirectTo, closeModal },
    };
};

export const createRmaTicket = ({ data, formName, refresh, onSuccess, onFailure, addRemark }) => ({
    type: tickets.CREATE_RMA_TICKET_REQUEST,
    payload: { data, formName, refresh, onSuccess, onFailure, addRemark },
});

export const getTicketStatsByCustomerId = (customerId) => ({
    type: tickets.GET_TICKET_STATS_REQUEST,
    payload: { customerId },
});

export const exportTicketCsvData = ({ tab, projectId, customerId, endUserId, status, emails, data, type, formName, filterData, module }) => ({
    type: tickets.EXPORT_TICKET_CSV_DATA_REQUEST,
    payload: { tab, projectId, customerId, endUserId, status, emails, data, type, formName, filterData, module },
});

export const bulkUploadSites = ({ project_id, formName, file, refresh }) => ({
    type: tickets.BULK_UPLOAD_SITE_REQUEST,
    payload: { project_id, formName, file, refresh },
});

export const bulkUploadSitesMulti = ({ form, type, projectId, customerId, limit, page }) => ({
    type: tickets.BULK_UPLOAD_SITE_MULTI_REQUEST,
    payload: { type, projectId, form, customerId, limit, page },
});

export const addNewSites = ({ projectId, data, existing, addNew, formName }) => ({
    type: tickets.ADD_NEW_SITE_REQUEST,
    payload: { projectId, data, existing, addNew, formName },
});

export const raisePO = ({ formValues, existing, addNew, formName, navigate, refresh }) => ({
    type: tickets.RAISE_PO_REQUEST,
    payload: { formValues, existing, addNew, formName, navigate, refresh },
});

export const getTicketsByIds = ({ ids }) => ({
    type: tickets.GET_TICKETS_BY_IDS_REQUEST,
    payload: { ids },
});

export const updateTicketPaymentAllowance = ({ customer_additional_expenses, ticket_id }) => ({
    type: tickets.UPDATE_TICKET_PAYMENT_ALLOWANCE_REQUEST,
    payload: { customer_additional_expenses, ticket_id },
});

export const removePoDetailsFromStore = () => ({
    type: tickets.REMOVE_PO_DETAILS_FROM_STORE,
});

export const getTicketCount = ({ customerId, projectIds }) => ({
    type: tickets.GET_TICKETS_COUNT_REQUEST,
    payload: { customerId, projectIds },
});

export const bulkUploadTicketFile = ({ property = 'TICKETS', formName, customerId, projectId, ispId, files, refresh }) => ({
    type: tickets.BULK_UPLOAD_TICKET_FILE_REQUEST,
    payload: { property, customerId, projectId, ispId, files, formName, refresh },
});

export const askAppointment = ({ ticketId, email, formName, mobile, cc }) => ({
    type: tickets.ASK_APPOINTMENT_REQUEST,
    payload: { ticketId, email, formName, mobile, cc },
});


export const unlockSignoff = ({ formName, ticketId, remark }) => ({
    type: tickets.UNLOCK_SIGNOFF_REQUEST,
    payload: { ticketId, formName, remark },
});


export const getIssueCategoriesFilterList = ({ customerId, dropdown }) => ({
    type: tickets.GET_ISSUE_CATEGORY_FILTER_REQUEST,
    payload: { customerId, dropdown },
});


export const getActiveVoiceCall = () => ({
    type: tickets.GET_ACTIVE_VOICE_CALL_REQUEST,
});

export const createSupportTrackTickets = ({ data, existing, addNew, formName, onSuccess, refresh, onFailure, redirectToSupportTicket, navigate, additional_documents, tagId }) => ({
    type: tickets.CREATE_SUPPORT_TRACK_TICKETS_REQUEST,
    payload: { data, existing, addNew, formName, onSuccess, refresh, onFailure, redirectToSupportTicket, navigate, additional_documents, tagId },
});



export const moveTORma = ({
    formName,
    onSuccess,
    ticketId,
    rma_request_type,
    assetId,
    description,
    executionDate,
    repairCenter,
    warehouse,
    warehouse_owner
}) => {
    const form = new FormData();
    form.set('move_to_rma', 1);
    form.set('rma_request_type', rma_request_type);
    form.set('asset_id', assetId);
    form.set('ticket_id', ticketId);
    form.set('description', description);
    form.set('warehouse', warehouse);
    form.set('warehouse_owner', warehouse_owner);
    form.set('repair_center', repairCenter);
    if (executionDate) form.set('execution_date', moment(executionDate).format('DD-MM-YYYY HH:mm:ss'));
    return {
        type: tickets.MOVE_TO_RMA_REQUEST,
        payload: { form, formName, onSuccess },
    };
};

export const bulkHardCopyRecievedUpdate = ({ ticketIds, formName }) => ({
    type: tickets.BULK_HARDCOPY_RECIEVED_UPDATE_REQUEST,
    payload: { ticketIds, formName },
});

export const callOnTicketDetailsCopy = ({ ticketId }) => ({
    type: tickets.CALL_ON_COPY_TICKET_DETAILS_REQUEST,
    payload: { ticketId },
});
